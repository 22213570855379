import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { plansPageView } from '@wix/bi-logger-membership/v2';
import { PlanListWidgetRole } from '@wix/pricing-plans-common/blocks';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { hasSetupFee, isRecurringPlan } from '@wix/pricing-plans-utils';
import { demoImages, demoImagesStudio, plansFixtureTranslated } from '../../fixtures';
import { uncompressUuidArray } from '../../services/uuid-compression';
import { WarmupData } from '../../services/WarmupData';
import { PlanListBlocksInteraction } from '../../types/PlanListBlocksFedops';
import { toError } from '../../utils/errors';
import { getGlobalObject } from '../../utils/get-global-object';
import { getSiteBrand } from '../../utils/get-site-brand';
import { CtaClickHandler, PlanElementOptions } from '../Plan/viewer.controller';
import model, { PlanListWidgetProps } from './model';

export type PlanVariantState = Extract<
  PlanListWidgetRole,
  | PlanListWidgetRole.PlanVariantDefaultState
  | PlanListWidgetRole.PlanVariantHighlightedState
  | PlanListWidgetRole.PlanVariantCustomState
>;

type PlanVariantRole = Extract<
  PlanListWidgetRole,
  | PlanListWidgetRole.PlanVariantDefaultWidget
  | PlanListWidgetRole.PlanVariantHighlightedWidget
  | PlanListWidgetRole.PlanVariantCustomWidget
>;

type PlanListWidgetState = Extract<
  PlanListWidgetRole,
  | PlanListWidgetRole.EmptyState
  | PlanListWidgetRole.PlansInfoState
  | PlanListWidgetRole.LoadingState
  | PlanListWidgetRole.ErrorState
>;

interface RepeaterItemData {
  _id: string;
  plan: PublicPlan;
}

const PLAN_VARIANT_STATE_TO_WIDGET_VARIANT_ID: { [key in PlanVariantState]: `#${PlanVariantRole}` } = {
  [PlanListWidgetRole.PlanVariantDefaultState]: `#${PlanListWidgetRole.PlanVariantDefaultWidget}`,
  [PlanListWidgetRole.PlanVariantHighlightedState]: `#${PlanListWidgetRole.PlanVariantHighlightedWidget}`,
  [PlanListWidgetRole.PlanVariantCustomState]: `#${PlanListWidgetRole.PlanVariantCustomWidget}`,
};

export default model.createController(({ $w, $widget, flowAPI, controllerConfig }) => {
  const isStudio = getSiteBrand() === 'studio';
  const elementOptions: PlanElementOptions = {
    defaultCoverImage: isStudio ? demoImagesStudio.image3 : demoImages.image3,
  };
  const warmUpData = new WarmupData(controllerConfig.compId, controllerConfig.wixCodeApi, flowAPI);

  let externalOnSelectHandler: CtaClickHandler | undefined;
  let autoLoadPlans = true;
  const getDemoPlansIfEditor = () => {
    return flowAPI.environment.isEditor ? plansFixtureTranslated(flowAPI.translations.t, isStudio) : null;
  };

  const getOrderedPlans = async (planIds?: string[]) => {
    if (!planIds?.length) {
      return getDemoPlansIfEditor();
    }

    const cacheKey = await getCacheKey();
    const response =
      flowAPI.experiments.enabled(TPA_EXPERIMENTS.USE_WARM_UP_DATA_BLOCKS) && cacheKey
        ? await warmUpData.cache(cacheKey, () => flowAPI.httpClient.request(listPublicPlans({ planIds })))
        : await flowAPI.httpClient.request(listPublicPlans({ planIds }));

    return response.data.plans
      ? response.data.plans.sort((a, b) => (planIds.indexOf(a.id!) > planIds.indexOf(b.id!) ? 1 : -1))
      : getDemoPlansIfEditor();
  };

  const getCacheKey = async () => {
    const planIds = $widget.props.planIds;
    const planIdsHashed = await hashString(planIds);
    if (planIdsHashed) {
      return `plan-list-${planIdsHashed}`;
    }

    return null;
  };

  const showState = (state: PlanListWidgetState) => {
    $w(`#${PlanListWidgetRole.PlanListStates}`).changeState(state);
  };

  const setWidgetData = (
    plans: PublicPlan[],
    props: Pick<PlanListWidgetProps, 'customStylePlanIds' | 'highlightedPlanIds'>,
  ) => {
    setPricingElementOptions(plans);
    const highlightedPlanIds = getUncompressedPlanIds(props.highlightedPlanIds);
    const customStylePlanIds = getUncompressedPlanIds(props.customStylePlanIds);

    $w(`#${PlanListWidgetRole.PlanList}`).onItemReady(($item, { plan }: RepeaterItemData) =>
      updatePlanItem($item, { plan, highlightedPlanIds, customStylePlanIds }),
    );

    $w(`#${PlanListWidgetRole.PlanList}`).data = plans.map(
      (plan) =>
        ({
          _id: plan.id!,
          plan,
        } satisfies RepeaterItemData),
    );

    showState(PlanListWidgetRole.PlansInfoState);
  };

  const updateDisplayedPlans = async (newProps: PlanListWidgetProps) => {
    const planIds = getUncompressedPlanIds(newProps.planIds);
    const plans = await getOrderedPlans(planIds);
    await initializeRepeaterItems(planIds);
    if (plans) {
      setWidgetData(plans, newProps);
    }
  };

  const updatePlanStyleVariants = async (newProps: PlanListWidgetProps) => {
    const highlightedPlanIds = getUncompressedPlanIds(newProps.highlightedPlanIds);
    const customStylePlanIds = getUncompressedPlanIds(newProps.customStylePlanIds);
    $w(`#${PlanListWidgetRole.PlanList}`).forEachItem(($item, { plan }: RepeaterItemData) =>
      updatePlanItem($item, { plan, highlightedPlanIds, customStylePlanIds }),
    );
  };

  $widget.onPropsChanged((oldProps, newProps) => {
    if (hasDisplayedPlansChanged(oldProps, newProps)) {
      updateDisplayedPlans(newProps);
    } else if (hasPlanStyleVariantChanged(oldProps, newProps) || hasPlanCardOrientationChanged(oldProps, newProps)) {
      updatePlanStyleVariants(newProps);
    }
  });

  const updatePlanItem = (
    $item: $w.$w,
    data: {
      plan: PublicPlan;
      highlightedPlanIds?: string[];
      customStylePlanIds?: string[];
    },
  ) => {
    const { plan, highlightedPlanIds, customStylePlanIds } = data;
    if (!plan) {
      return;
    }
    const state = getPlanWidgetState({ planId: plan.id!, highlightedPlanIds, customStylePlanIds });
    $item(`#${PlanListWidgetRole.PlanVariantBox}`).changeState(state);
    const planId = PLAN_VARIANT_STATE_TO_WIDGET_VARIANT_ID[state];
    $item(planId).setPlan(plan, getElementOptions());
    $item(planId).registerCtaHandler(plan, 'list_blocks');
    if (externalOnSelectHandler) {
      $item(planId).onSelect(externalOnSelectHandler);
    }
  };

  const initializeRepeaterItems = (planIds: string[] | undefined) => {
    if (!planIds?.length) {
      return;
    }
    /*
      Set plan ids on repeater to initialize repeater items and call
      `pageReady` on inner widgets. This will prevent an issue, where
      repeater's `onItemReady` is triggered before `pageReady` in inner widget
    */
    return new Promise((resolve) => {
      $w(`#${PlanListWidgetRole.PlanList}`).onItemReady(resolve);
      $w(`#${PlanListWidgetRole.PlanList}`).data = planIds?.map((id) => ({ _id: id })) ?? [];
    });
  };

  const loadEmptyState = () => {
    $w('#text6').text = flowAPI.translations.t('blocks.plan-list.empty-state.title');
    $w('#text7').text = flowAPI.translations.t('blocks.plan-list.empty-state.subtitle');
    return showState(PlanListWidgetRole.EmptyState);
  };

  const loadErrorState = (planIds?: string[]) => {
    $w('#button1').onClick(() => fetchAndSetPlans(planIds));
    $w('#text5').text = flowAPI.translations.t('blocks.plan-list.error-state.title');
    $w('#text4').text = flowAPI.translations.t('blocks.plan-list.error-state.subtitle');
    $w('#button1').label = flowAPI.translations.t('blocks.plan-list.error-state.button-label');
    return showState(PlanListWidgetRole.ErrorState);
  };

  const fetchAndSetPlans = async (planIds?: string[]) => {
    flowAPI.fedops.interactionStarted(PlanListBlocksInteraction.FetchAndSetPlans);
    flowAPI.panoramaClient?.transaction(PlanListBlocksInteraction.FetchAndSetPlans).start();
    try {
      await initializeRepeaterItems(planIds);
      const plans = await getOrderedPlans(planIds);
      if (!plans?.length) {
        return loadEmptyState();
      }
      setWidgetData(plans, $widget.props);
      flowAPI.bi?.report(plansPageView({ widgetType: 'list_blocks' }));
      flowAPI.fedops.interactionEnded(PlanListBlocksInteraction.FetchAndSetPlans);
      flowAPI.panoramaClient?.transaction(PlanListBlocksInteraction.FetchAndSetPlans).finish();
    } catch (e) {
      loadErrorState(planIds);
      flowAPI.errorMonitor?.captureException(toError(e));
    }
  };

  const setPricingElementOptions = (plans: PublicPlan[]) => {
    elementOptions.forceExpandFrequency = plans.some((plan) => isRecurringPlan(plan));
    elementOptions.forceExpandSetupFee = plans.some((plan) => hasSetupFee(plan));
    elementOptions.forceExpandFreeTrial = plans.some((plan) => Boolean(plan.pricing?.freeTrialDays));
  };

  const getElementOptions = (): PlanElementOptions => {
    const shouldExpandEmptyElements = !$widget.props.isHorizontal && !flowAPI.environment.isMobile;
    if (shouldExpandEmptyElements) {
      return elementOptions;
    }

    return {
      ...elementOptions,
      forceExpandFreeTrial: false,
      forceExpandFrequency: false,
      forceExpandSetupFee: false,
    };
  };

  return {
    pageReady: async () => {
      flowAPI.fedops.interactionStarted(PlanListBlocksInteraction.PlanListPageReady);
      flowAPI.panoramaClient?.transaction(PlanListBlocksInteraction.PlanListPageReady).start();

      const isWidgetRendered = $w(`#${PlanListWidgetRole.PlanList}`).rendered;
      if (!autoLoadPlans || (flowAPI.environment.isEditor && !isWidgetRendered)) {
        return;
      }

      if (!flowAPI.environment.isEditor && !flowAPI.environment.isSSR) {
        showState(PlanListWidgetRole.LoadingState);
      }
      const planIds = getUncompressedPlanIds($widget.props.planIds);
      await fetchAndSetPlans(planIds);

      flowAPI.fedops.interactionEnded(PlanListBlocksInteraction.PlanListPageReady);
      flowAPI.panoramaClient?.transaction(PlanListBlocksInteraction.PlanListPageReady).finish();
    },
    exports: {
      onSelect: (cb: CtaClickHandler) => {
        externalOnSelectHandler = cb;
      },
      setPlans: async (planIds: string[]) => {
        autoLoadPlans = false;
        fetchAndSetPlans(planIds);
      },
      setTitle: (title: string) => {
        $w(`#${PlanListWidgetRole.Title}`).text = title;
      },
      setSubtitle: (subtitle: string) => {
        $w(`#${PlanListWidgetRole.Subtitle}`).text = subtitle;
      },
    },
  };
});

function getUncompressedPlanIds(planIdsProp?: string): string[] | undefined {
  return planIdsProp ? uncompressUuidArray(planIdsProp.split(',')).filter((id) => id !== '') : undefined;
}

function getPlanWidgetState(params: {
  planId: string;
  highlightedPlanIds?: string[];
  customStylePlanIds?: string[];
}): PlanVariantState {
  const { planId, highlightedPlanIds, customStylePlanIds } = params;
  if (highlightedPlanIds?.includes(planId)) {
    return PlanListWidgetRole.PlanVariantHighlightedState;
  }

  if (customStylePlanIds?.includes(planId)) {
    return PlanListWidgetRole.PlanVariantCustomState;
  }

  return PlanListWidgetRole.PlanVariantDefaultState;
}

function hasDisplayedPlansChanged(oldProps: PlanListWidgetProps, newProps: PlanListWidgetProps): boolean {
  return oldProps.planIds !== newProps.planIds;
}

function hasPlanStyleVariantChanged(oldProps: PlanListWidgetProps, newProps: PlanListWidgetProps): boolean {
  return (
    oldProps.customStylePlanIds !== newProps.customStylePlanIds ||
    oldProps.highlightedPlanIds !== newProps.highlightedPlanIds
  );
}

function hasPlanCardOrientationChanged(oldProps: PlanListWidgetProps, newProps: PlanListWidgetProps): boolean {
  return oldProps.isHorizontal !== newProps.isHorizontal;
}

async function hashString(str: string): Promise<string | null> {
  try {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const globalObj = getGlobalObject();
    if (!globalObj) {
      return null;
    }

    const hashBuffer = await globalObj.crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  } catch (e) {
    return null;
  }
}
